import React from "react";
import css from "./footer.module.css";
import Logo from "../../assets/logo.png";
import {
  InboxIcon,
  PhoneIcon,
  LocationMarkerIcon,
  LoginIcon,
  UsersIcon,
  LinkIcon,
} from "@heroicons/react/outline";
const footer = () => {
  return (
    <div>
      <div className={css.cFooterWrapper}>
        <hr />

        <div className={css.cFooter}>
          <div className={css.logo}>
            <img src={Logo} alt="" />
            <span>amazon</span>
          </div>

          <div className={css.block}>
            <div className={css.detail}>
              <span>Contact US</span>
              <span className={css.pngLine}>
                <LocationMarkerIcon className={css.icon} />
                 <span className={css.icon} >111 north avenue orlando, FL  32801</span>
              </span>
              <span className={css.pngLine}>
                <PhoneIcon className={css.icon} />
                 <span className={css.icon} >+91 6398559673</span>
              </span>
              <span className={css.pngLine}>
                <InboxIcon className={css.icon} />
                 <span className={css.icon} >akum5991@gmail.com</span>
              </span>

              
            </div>
          </div>

          <div className={css.block}>
          <div className={css.detail}>
            <span>Accounts</span>
            <span className={css.pngLine}>
                <LoginIcon className={css.icon}/>
                <span>Sign-in</span>
            </span>
          </div>
          </div>

          <div className={css.block}>
          <div className={css.detail}>
            <span>Company</span>
            <span className={css.pngLine}>
                <UsersIcon className={css.icon}/>
                <span>About-us</span>
            </span>
          </div>
          </div>

          <div className={css.block}>
          <div className={css.detail}>
            <span>Resources</span>
            <span className={css.pngLine}>
                <LinkIcon className={css.icon}/>
                <span>saftey-privcy&service</span>
            </span>
          </div>
          </div>


        </div>

        <div className={css.copyRight}>
            <span>Copyright @2022 by Amazon .Inc</span>
            <span>All Right reserved</span>
        </div>
      </div>
    </div>
  );
};

export default footer;
