import React from "react";
import css from "./Testimonials.module.css";
import Hero from "../../assets/testimonialHero.png";
import {TestimonialsData} from '../../data/testimonials';
// import Swiper from "swiper/react";
import { Swiper,SwiperSlide } from "swiper/react";

const Testimonials = () => {
  return (
    <div>
      <div className={css.testimonials}>
        <div className={css.wrapper}>
          <span>Top Rated</span>
          <br />
          <span>
            Seedily day has suitable disposal and boy .
          </span>
          <img src={Hero} alt="" className="img" />

          <div className={css.container}>
            <span>100k</span>
            <span>Happy Customers with us</span>
          </div>
        </div>
      </div>
     <div className={css.reviews}>Reviews</div>
      <div className={css.carousal}>
        <Swiper
         slidesPerView={3}
         slidesPerGroup={1}
         spaceBetween={20}
         className={css.tCarousel}

        >
            {
                TestimonialsData.map((testimonial, i)=>(
                  <SwiperSlide>
                    <div className={css.testimonial}>
                      <img src={testimonial.image} alt="" />
                      <span>{testimonial.comment}</span>
                      <span>{testimonial.name}</span>
                    </div>
                  </SwiperSlide>
                ))
            }
        </Swiper>
      </div>  
    </div>
  );
};

export default Testimonials;
