import React from 'react'
import css from './Hero.module.css'
import HeroImg from '../../assets/hero.png'
import {RiShoppingBagFill} from 'react-icons/ri' 
import {BsArrowRight} from 'react-icons/bs'
import {motion} from 'framer-motion'

const Hero = () => {

  const transition = {duration: 3, type: "spring"}
  return (
    <div className={css.container}>

     {/* left side */}
      <div className={css.h_sides} >
        <span className={css.text1}>skin protection creem</span>

        <div className={css.text2}>
          <span>Treandy collection</span>
          <span>{" "} 
          Seedly say has suyitable disposa; and . Exercise joy man childeren rejoiced</span>
        </div>
      </div>
      
      {/*  middle side hero  image  */}
      <div className={css.wrapper}>

      {/* blue circle */}
          <motion.div
          initial={{bottom:'5rem'}}
          whileInView={{bottom:"0rem"}}
          transition={transition}
           className={css.blueCircle}></motion.div>

           {/* hero image */}
          <motion.img
          transition={transition}
          initial={{top:'5rem'}}
          whileInView={{top:'0rem'}}
          src={HeroImg} alt="beauty" />

          <motion.div
          transition={transition}
          initial={{right:"4%"}}
          whileInView={{right:"-6%"}}
           className={css.cart2}>
             <RiShoppingBagFill/>

             <div className={css.signup}>
              <span>Best signUp Offers</span>

              <div>
                <BsArrowRight/>
              </div>
             </div>
          </motion.div>
      </div>

      {/* right  side  */}
      <div className={css.h_sides}>
        <div className={css.traffic}>
           <span>1.5m</span>
           <span>Monthly traffic</span>
        </div>

        <div className={css.customers}>
          <span>100k</span>
          <span>Happy Customer</span>
        </div>
      </div>
    </div>
  )
}

export default Hero
